import Vue from "vue";
import router from "@/router";
import VueAuthenticate  from "vue-authenticate";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

var vueAuth = new VueAuthenticate(Vue.prototype.$http, {
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  tokenName: "access_token",
  loginUrl: "/login",
  registerUrl: "/register"
});

export default {
  state: {
    isAuthenticated: localStorage.getItem("access-token") !== null
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    }
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    }
  },

  actions: {
    async login(context, payload) {
      try{
        let result=await axios.post(process.env.VUE_APP_API_BASE_URL+"/login",payload.user);
        
        localStorage.setItem("access-token",result.data.token)
        context.commit("isAuthenticated", {
          isAuthenticated: (localStorage.getItem("access-token") !== null)
        });
        router.push({name: "Home"})
      }
      catch(error)
      {
        return error.response;
      }
    },

    async register(context, payload) {
      
      
      try{
        let result=await axios.post(process.env.VUE_APP_API_BASE_URL+"/register",payload.user);
        localStorage.getItem("access-token",result.data.token)
        context.commit("isAuthenticated", {
          isAuthenticated: (localStorage.getItem("access-token") !== null)
        });
        router.push({name: "Home"})
      }
      catch(error)
      {
        return error.response;
      }
    },

    logout(context, payload) {
      localStorage.removeItem("access-token");
      location.reload();
      router.push({path: "Login"});
    }
  }
};
