import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

async function get() {
  let reqInstance = axios.create({
    headers: {
      Authorization : `Bearer ${localStorage.getItem("access-token")}`
      }
    });
  return await reqInstance.get(`${url}/me`);
}

function update(profile) {
  console.info(profile)
  const payload = jsona.serialize({
    stuff: profile,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/me`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

export default {
  get,
  update
};
