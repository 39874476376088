import axios from "axios";
import store from "@/store";
const url = process.env.VUE_APP_API_BASE_URL;

const actions = {
  
  async forgotPassword({ commit }, data) {
    
    await axios.post(`${url}/password-forgot`, { email: data});
  },
  async createNewPassword({ commit }, data) {
    await axios.post(`${url}/password-reset`, { token:data.token,password:data.password, password_confirmation:data.password_confirmation });
  }
};

const reset = {
  namespaced: true,
  actions
};

export default reset;
