var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.dashboard'),
          icon: 'tim-icons icon-chart-pie-36',
          path: '/dashboard'
        }}}),(_vm.roles)?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.setting'), icon: 'tim-icons icon-settings-gear-63' }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.resetpassword'), path: '/setting/reset-password' }}})],1):_vm._e(),(_vm.roles)?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.apiExamples'), icon: 'fab fa-vuejs fa-2x' }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.userProfile'), path: '/examples/user-profile' }}}),(_vm.roles.includes('admin'))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.roleManagement'), path: '/examples/role-management/list-roles'}}}):_vm._e(),(_vm.roles.includes('admin'))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.userManagement'), path: '/examples/user-management/list-users'}}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.categoryManagement'), path: '/examples/category-management/list-categories'}}}),(_vm.roles.includes('admin') || _vm.roles.includes('creator'))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.tagManagement'), path: '/examples/tag-management/list-tags'}}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.itemManagement'), path: '/examples/item-management/list-items' }}})],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.pages'), icon: 'tim-icons icon-image-02' }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.pricing'), path: '/pricing' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.rtl'), path: '/pages/rtl' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.timeline'), path: '/pages/timeline' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.lock'), path: '/lock' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.userProfile'), path: '/pages/user' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.components'),
          icon: 'tim-icons icon-molecule-40'
        }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.multiLevelCollapse') }}},[_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('sidebar.example'),
              isRoute: false,
              path: 'https://google.com',
              target: '_blank'
            }}})],1),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.buttons'), path: '/components/buttons' }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.gridSystem'),
            path: '/components/grid-system'
          }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.panels'), path: '/components/panels' }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.sweetAlert'),
            path: '/components/sweet-alert'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.notifications'),
            path: '/components/notifications'
          }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.icons'), path: '/components/icons' }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.typography'),
            path: '/components/typography'
          }}})],1),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.forms'), icon: 'tim-icons icon-notes' }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.regularForms'), path: '/forms/regular' }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.extendedForms'),
            path: '/forms/extended'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.validationForms'),
            path: '/forms/validation'
          }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.wizard'), path: '/forms/wizard' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.tables'),
          icon: 'tim-icons icon-puzzle-10'
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.regularTables'),
            path: '/table-list/regular'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.extendedTables'),
            path: '/table-list/extended'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.paginatedTables'),
            path: '/table-list/paginated'
          }}})],1),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.maps'), icon: 'tim-icons icon-pin' }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.googleMaps'), path: '/maps/google' }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.fullScreenMaps'),
            path: '/maps/full-screen'
          }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.vectorMaps'), path: '/maps/vector-map' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.widgets'),
          icon: 'tim-icons icon-settings',
          path: '/widgets'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.charts'),
          icon: 'tim-icons icon-chart-bar-32',
          path: '/charts'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.calendar'),
          icon: 'tim-icons icon-time-alarm',
          path: '/calendar'
        }}})]}}])}),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }